
import { post } from "@/utils/request";
import { message } from "ant-design-vue";
import { defineComponent, ref } from "vue";
import { UploadOutlined } from '@ant-design/icons-vue';
import { getCiYun } from "@/api"

export default defineComponent({
  components: {
    UploadOutlined
  },
  setup() {
    let filename: string;
    const fileList = ref("");
    const text = ref("");
    const loading = ref(false);
    const selectVal = ref("1");
    const ciyunlink = ref("");
    const visible = ref(false);
    const ciyunBtnText = ref("生成词云");
    const uploadImg = (option: any) => {
      const file = option.file
      const params = new FormData()
      params.append('file', file);
      const uId = file.uid.split("-")[2] + file.uid.split("-")[3]
      params.append('fileUid', uId);
      if (option.file.type != "image/jpeg" && option.file.type != "image/png" && option.file.type != "image/webp") {
        return message.warning("文件类型只能为jpg/png/webp格式")
      }
      if (option.file.size / 1024 / 1024 > 2) {
        return message.warning("文件大小不能超过2MB")
      }
      let fileType: string;
      if (file.type.split("/")[1] == "jpeg") {
        fileType = "jpg";
      } else {
        fileType = file.type.split("/")[1];
      }
      post("/tools/uploadCiYunImg", params, {
        onUploadProgress: (progressEvent: any) => {
          option.onProgress({ percent: (progressEvent.loaded / progressEvent.total * 100 | 0) });
        }
      }).then((res: any) => {
        if (res.code == 500) {
          return message.error("上传失败！");
        }
        option.onSuccess(res, option.file);
        filename = uId + "." + fileType;
        message.success("上传成功!");
      })
    }
    const ciYun = () => {
      if (!filename) {
        return message.warning("请先上传图片");
      }
      if (filename == "1") {
        return message.warning("请重新上传图片");
      }
      if (!text.value) {
        return message.warning("请输入内容");
      }
      loading.value = true;
      ciyunBtnText.value = "生成词云中";
      getCiYun({value: selectVal.value, text: text.value, filename}).then((res: any) => {
        loading.value = false;
        ciyunBtnText.value = "生成词云";
        if (res.code == 500) {
          return message.error("生成词云失败！");
        }
        ciyunlink.value = res.data.data.img;
        message.success("生成词云成功！");
        filename = "1";
      }).catch(() => {
        ciyunBtnText.value = "生成词云";
        loading.value = false;
      })
    }
    return {
      fileList,
      text,
      visible,
      loading,
      ciyunBtnText,
      selectVal,
      uploadImg,
      ciYun,
      ciyunlink
    }
  }
})
