<template>
  <div id="ciyun" class="center">
    <a-spin :spinning="false">
      <div class="title">
        <strong>在线生成词云</strong>
      </div>
      <a-upload
        list-type="picture"
        v-model:fileList="fileList"
        :customRequest="uploadImg"
      >
        <a-button> <upload-outlined /> 上传图片 </a-button>
      </a-upload> 
      <div style="margin: 20px 0px;"></div>
      <a-textarea v-model:value="text" placeholder="请输入词云内容" :rows="5" />
      <div style="margin: 20px 0px;"></div>
      <a-select
          v-model:value="selectVal"
          @focus="focus"
          ref="select"
          @change="handleChange"
        >
          <a-select-option value="1">
            白底背景
          </a-select-option>
          <a-select-option value="2">
            自动识别
          </a-select-option>
        </a-select>
      <a-button style="margin: 0 20px;" type="primary" @click="visible = !visible">使用说明</a-button>
      <a-button @click="ciYun" :loading="loading" type="primary">{{ ciyunBtnText }}</a-button>
      <div v-if="ciyunlink" style="margin-top: 40px;display: flex;justify-content: center;">
        <a target="_blank" style="font-size: 18px;" :href="ciyunlink">
          <img :src="ciyunlink" alt="" srcset="">
        </a>
      </div>
    </a-spin>
    <a-modal v-model:visible="visible" title="友情提示" @ok="visible = !visible">
      <p>1、首先上传图片，最好用白色背景或对比度明显的图片，系统会根据图片形状绘制词云形状</p>
  		<p><span style="color: red;">白底背景:</span> <span>自己将图片背景用ps处理为白色，其它为词云形状</span></p>
  		<p><span style="color: red;">自动识别:</span> <span>系统去除图片背景，绘制词云形状</span></p>
  		<p style="color: red;">最好使用ps将图片转化为白色背景</p>
  		<p>2、输入你想要生成词云的文字</p>
  		<p>3、点击 生成词云 按钮</p>
  		<p>4、生成词云，点击词云地址打开词云，在词云图片上鼠标右击选择另存为保存图片</p>
      <p><span style="color: red;">注意：图片不能太大，否则会生成词云失败！</span></p>
    </a-modal>
  </div>
</template>

<script lang="ts">
import { post } from "@/utils/request";
import { message } from "ant-design-vue";
import { defineComponent, ref } from "vue";
import { UploadOutlined } from '@ant-design/icons-vue';
import { getCiYun } from "@/api"

export default defineComponent({
  components: {
    UploadOutlined
  },
  setup() {
    let filename: string;
    const fileList = ref("");
    const text = ref("");
    const loading = ref(false);
    const selectVal = ref("1");
    const ciyunlink = ref("");
    const visible = ref(false);
    const ciyunBtnText = ref("生成词云");
    const uploadImg = (option: any) => {
      const file = option.file
      const params = new FormData()
      params.append('file', file);
      const uId = file.uid.split("-")[2] + file.uid.split("-")[3]
      params.append('fileUid', uId);
      if (option.file.type != "image/jpeg" && option.file.type != "image/png" && option.file.type != "image/webp") {
        return message.warning("文件类型只能为jpg/png/webp格式")
      }
      if (option.file.size / 1024 / 1024 > 2) {
        return message.warning("文件大小不能超过2MB")
      }
      let fileType: string;
      if (file.type.split("/")[1] == "jpeg") {
        fileType = "jpg";
      } else {
        fileType = file.type.split("/")[1];
      }
      post("/tools/uploadCiYunImg", params, {
        onUploadProgress: (progressEvent: any) => {
          option.onProgress({ percent: (progressEvent.loaded / progressEvent.total * 100 | 0) });
        }
      }).then((res: any) => {
        if (res.code == 500) {
          return message.error("上传失败！");
        }
        option.onSuccess(res, option.file);
        filename = uId + "." + fileType;
        message.success("上传成功!");
      })
    }
    const ciYun = () => {
      if (!filename) {
        return message.warning("请先上传图片");
      }
      if (filename == "1") {
        return message.warning("请重新上传图片");
      }
      if (!text.value) {
        return message.warning("请输入内容");
      }
      loading.value = true;
      ciyunBtnText.value = "生成词云中";
      getCiYun({value: selectVal.value, text: text.value, filename}).then((res: any) => {
        loading.value = false;
        ciyunBtnText.value = "生成词云";
        if (res.code == 500) {
          return message.error("生成词云失败！");
        }
        ciyunlink.value = res.data.data.img;
        message.success("生成词云成功！");
        filename = "1";
      }).catch(() => {
        ciyunBtnText.value = "生成词云";
        loading.value = false;
      })
    }
    return {
      fileList,
      text,
      visible,
      loading,
      ciyunBtnText,
      selectVal,
      uploadImg,
      ciYun,
      ciyunlink
    }
  }
})
</script>

<style lang="scss" scoped>
  #ciyun {
    min-height: 400px; 

    .title {
      font-size: 25px;
      color: #037afb;
      text-align: center;
      margin: 25px 0;
    } 
    
    a {

      img {
        max-width: 100%;
      }
    }
  }

</style>